import axios from '../utils/axios';
import { PLATFORM_DATA_URL } from '../config';
import { UNK_ERROR } from '../constants';

// Provides a service used to retrieve report data sources
// from the server. This is a managed process for ease of use.
class GenericDataService {

  postDataRequest = (id, params) => new Promise((resolve, reject) => {
    axios.post(PLATFORM_DATA_URL + '/' + id, params)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(UNK_ERROR);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });


  getAllAssets = () => new Promise((resolve, reject) => {
    axios.get(PLATFORM_DATA_URL + '/assets/all')
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(UNK_ERROR);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });

  getAssetById = (assetId) => new Promise((resolve, reject) => {
    axios.get(PLATFORM_DATA_URL + '/assets/' + assetId)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(UNK_ERROR);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });

  reportProductionByDate = (start, end) => new Promise((resolve, reject) => {
    axios.get('https://wrecws.impactsvc.com/report/production/summary/' + start + '/' + end)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(UNK_ERROR);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });

}

const dataService = new GenericDataService();
export default dataService;
